import configs from "../config/main.json";
import { PlatformIdMapping } from "../utility/plaformIcons";
import { getResponseType } from "../utility/exportData";
import { logout } from "../redux/features/user/userSlice";
import { notify } from "../redux/features/system/systemAlert";
import { createSignedRequest, requestConfig } from "./common";
import axios from "axios";

const { API_HOST, ORYX_HOSTNAME, INFLUENCER_HOST } = configs;

/* 
  get details of all campaigns
*/
const getAllInfluencerListForCampaign = async (lids, platform, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/influencers/name`,
      data: JSON.stringify({
        lids,
        platform,
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getDetailedInfluencerInformation = async (influencer_id, user, dispatch) => {

  const request = {
    hostname: ORYX_HOSTNAME,
    method: "GET",
    url: `${INFLUENCER_HOST}/influencer/${influencer_id}`,
  };

  const signedRequest = createSignedRequest(request, user);
  const url = signedRequest.url;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
}

const getDetailedProfileInformation = (influencer_id, platform, onboarded_type) => {
  const urls = [
    `${INFLUENCER_HOST}/profile/social_platform/${influencer_id}/${platform}/${onboarded_type}`,
    `${INFLUENCER_HOST}/profile/basic/${influencer_id}/${onboarded_type}`,
    `${INFLUENCER_HOST}/profile/contact/${influencer_id}`,
    `${INFLUENCER_HOST}/profile/education/${influencer_id}`,
    `${INFLUENCER_HOST}/profile/employment/${influencer_id}`,
    `${INFLUENCER_HOST}/profile/interests/${influencer_id}`,
  ];
  return Promise.all(
    urls?.map((url) =>
      fetch(url)
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => console.log("There was a problem!", error))
    )
  );
};

function checkStatus(response) {
  if (response.ok) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(new Error(response.statusText));
  }
}

function parseJSON(response) {
  return response.json();
}

const getDetailedInfluencerList = async (
  cid,
  plid,
  lids,
  page,
  rows,
  SortCol,
  SortOrder,
  isExport,
  isRefreshed,
  mimeType,
  campaignName,
  timeZone,
  lastPostedStatus,
  user,
  dispatch
) => {
  try {
    const platformIds = [];
    plid.forEach((platform) => platformIds.push(PlatformIdMapping[platform]));
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/influencers`,
      data: JSON.stringify({
        cid,
        plid: platformIds,
        lids,
        page,
        rows,
        SortCol,
        SortOrder,
        isExport,
        isRefreshed,
        mimeType,
        campaignName,
        account: user.agencyId,
        client: user.clientId,
        path: "Campaigns.Fields.Influencers",
        timeZone,
        lastPostedStatus,
      }),
      responseType: getResponseType(isExport),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    if (!mimeType || mimeType == "") {
      return { status: response.ok, response: await Promise.resolve(response.json()) };
    }
    return { status: response.ok, response: await Promise.resolve(response.text()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getInfluencerIdByList = async (lid, user, dispatch) => {
  const request = {
    hostname: ORYX_HOSTNAME,
    method: "GET",
    url: `${API_HOST}/list/influencers/id/${lid}`,
  };

  const signedRequest = createSignedRequest(request, user);

  try {
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest))

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const getInfluencerWithInvitationStatus = async (iids, lid, platform, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/influencer/invitaion`,
      data: JSON.stringify({
        iids,
        lid,
        platform
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const inviteInfluencerByList = async (campaign_id, audience_id, influencer_id, cname, medias, action, notificationMetadata, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/list/influencer/invite`,
      data: JSON.stringify({
        campaign_id, audience_id, influencer_id, cname, medias, action, notificationMetadata
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

const inviteInfluencerByEmail = async (campaign_id, audience_id, emails, cname, medias, action, user, dispatch) => {
  try {
    const request = {
      hostname: ORYX_HOSTNAME,
      method: "POST",
      url: `${API_HOST}/campaign/influencers/invite`,
      data: JSON.stringify({
        campaign_id, audience_id, emails, cname, medias, action
      }),
    };

    const signedRequest = createSignedRequest(request, user);
    const response = await fetch(signedRequest.url, requestConfig(request, signedRequest));
    if (response.status === 403) {
      dispatch(
        notify({
          message: response?.message,
          type: "error",
        })
      );
      dispatch(logout());
    }
    return { status: response.ok, response: await Promise.resolve(response.json()) };
  } catch (error) {
    dispatch(
      notify({
        message: error.response?.message,
        type: "error",
      })
    );
    dispatch(logout());
  }
};

export { getAllInfluencerListForCampaign, getDetailedInfluencerList, getDetailedProfileInformation, getDetailedInfluencerInformation, getInfluencerIdByList, getInfluencerWithInvitationStatus, inviteInfluencerByList, inviteInfluencerByEmail };
