import { Assessment, CachedOutlined, MoreHorizOutlined, PersonAddOutlined, Archive, Unarchive, Pause, PlayArrow } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../../styles/internal/Campaign/CampaignList.module.css";
import PropTypes from "prop-types";

const CampaignActionButton = ({ selectedRow, setPptReportId, handleCampaignStatusChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <PersonAddOutlined htmlColor="#B5B5C3" sx={{ color: "#008000" }} />,
      label: "Edit Campaign",
      name: "editcampaign",
      visible: true,
      disabled: selectedRow.universal,
    },
    {
      id: 2,
      icon: <CachedOutlined htmlColor="#B5B5C3" sx={{ color: "#0000FF" }}/>,
      label: "View Analytics",
      name: "viewanalytics",
      visible: true,
      disabled: false,
    },
    {
      id: 3,
      icon: <Assessment htmlColor="#B5B5C3" sx={{ color: "#800080" }} />,
      label: "Reports",
      name: "reports",
      visible: selectedRow.current_status === "Scheduled" ? false : true,
      disabled: false,
    },
    {
      id: 4,
      icon: selectedRow.current_status === "Archived" ? <Unarchive htmlColor="#B5B5C3" sx={{ color: "#808080" }} /> : <Archive htmlColor="#B5B5C3" sx={{ color: "#808080" }} />,
      label: selectedRow.current_status === "Archived" ? "Unarchive Campaign" : "Archive Campaign",
      name: selectedRow.current_status === "Archived" ? "unarchivecampaign" : "archivecampaign",
      visible: true,
      disabled: false,
    },
    {
      id: 5,
      icon: <Pause htmlColor="#B5B5C3"sx={{ color: "#FFA500" }} />,
      label: "Pause this Campaign",
      name: "pause",
      visible: selectedRow.current_status === "Live",
      disabled: false,
    },
    {
      id: 6,
      icon: <PlayArrow htmlColor="#B5B5C3" sx={{ color: "#00CC00" }} />,
      label: "Resume this Campaign",
      name: "resume",
      visible: selectedRow.current_status === "Paused",
      disabled: false,
    },
    // {
    //   id: 4,
    //   icon: <ControlPointDuplicateOutlined htmlColor="#B5B5C3" />,
    //   label: "Add Post",
    //   name: "addpost",
    // },
    // {
    //   id: 5,
    //   icon: <BuildOutlined htmlColor="#B5B5C3" />,
    //   label: "Manage",
    //   name: "manage",
    // },
  ];

  const invokeAction = async (name) => {
    switch (name) {
      case "viewanalytics": {
        navigate(`${location.pathname}/overview?id=${selectedRow.id}&universal=${selectedRow.universal}`, {
          replace: true,
        });
        break;
      }
      case "editcampaign": {
        navigate(`${location.pathname}/edit/wizard?id=${selectedRow.id}&eventType=${selectedRow.eventType}`, {
          replace: true,
        });
        break;
      }
      case "reports": {
        setPptReportId(selectedRow.id);
        handleClose();
        break;
      }
      case "archivecampaign": {
        await handleCampaignStatusChange(selectedRow,selectedRow.id, "Archived");
        break;
      }
      case "unarchivecampaign": {
        await handleCampaignStatusChange(selectedRow,selectedRow.id, "Active");
        break;
      }
      case "pause": {
        await handleCampaignStatusChange(selectedRow,selectedRow.id, "Paused");
        break;
      }
      case "resume": {
        await handleCampaignStatusChange(selectedRow,selectedRow.id, "Resume");
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreHorizOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30px" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions?.map(
            (value) =>
              value.visible && (
                <div key={value.id}>
                  <MenuItem disabled={value.disabled} onClick={() => invokeAction(value.name)}>
                    {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                  </MenuItem>
                </div>
              )
          )}
        </Menu>
      </Box>
    </>
  );
};

CampaignActionButton.propTypes = {
  selectedRow: PropTypes.object.isRequired,
  setPptReportId: PropTypes.func.isRequired,
  handleCampaignStatusChange: PropTypes.func.isRequired,
};

export default CampaignActionButton;
