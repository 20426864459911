import ReactEcharts from "echarts-for-react";
import { Card, Grid, Button } from "@mui/material";
import { BarChart, DoughnutChart } from "../../../common/chart";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import SummaryCard from "./SummaryCard";
import PropTypes from "prop-types";
import { InfoToolTip } from "../../../common/toolTip";
import constantMessages from "../../../../config/constantMessages.json";
import React, { useState } from "react";
import MyPaper from "../../../../utility/oryxUtils/MyPaper";
import config from "../../../../config/main.json";
import { useLocation, useNavigate } from "react-router-dom";
import { getCampaignOverview } from "../../../../services/campaignAnalytics";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const svg_icon = `${config.BASE_IMAGE_URL}Mask Group 1.svg`;
const svg_icon2 = `${config.BASE_IMAGE_URL}Group 26779.svg`;
const Note_Add = `${config.BASE_IMAGE_URL}Note Add.svg`;

const CampaignAnalyticsGraphs = ({
  totalEngagement,
  totalEngagementTime,
  platforms,
  publicationEngagement,
  publicationEngagementTime,
  engagementSummary,
  reachColorArray,
  reachSplit,
  engagementSplit,
  engagementColorArray,
  listEngagement,
  listEngagementTime,
  lists,
}) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const campaignId = params.get("id");
  const universal = params.get("universal");
  const [publicationPlatforms, setPublicationPlatforms] = useState();

  React.useEffect(() => {
    console.log(totalEngagement);
    getOverviewDetails();
  }, [totalEngagement]);

  const getOverviewDetails = async () => {
    const overview = await getCampaignOverview(+params.get("id"), user, dispatch);
    setPublicationPlatforms(overview.response.overallSummary[0].platforms)
  }


  const boxStyling = {
    width: "310px",
    height: "110px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }

  const buttonStyle = {
    backgroundColor: "#4285F4",
    borderRadius: "18px",
    width: "180px"
  };

  const iconStyle = {
    height: "20px",
    paddingRight: "5px"
  }

  const paraStyling = {
    margin: "10px 0 0",
    textAlign: "center",
    fontSize: "12px",
    width: "240px"
  }

  const handleAddPublicationButton = () => {
    navigate(`/app/campaigns/publications/published/create?id=${campaignId}&universal=${universal}`, { state: { data: publicationPlatforms , source : "snapshot"} });
  };

  return (
    <>
      {totalEngagementTime?.length === 0 ? (
        <MyPaper>
          <div style={{ textAlign: "center", width: "50vw", margin: "0 auto", height: "auto", paddingTop: "20px" }}>
            <div style={{ position: "relative", display: "inline-block", paddingBottom: "10px" }}>
              <img
                src={svg_icon2}
                alt="Icon"
                style={{
                  position: "absolute",
                  top: "-15px",
                  left: "18px",
                  zIndex: 2,
                }}
              />
              <img
                src={svg_icon}
                alt="Icon"
                style={{
                  position: "relative",
                  zIndex: 1,
                  width: '120px',
                  height: '120px'
                }}
              />
            </div>
            <p style={{ color: "#34343F", fontSize: "18px" }}><b>Nothing in the Campaign Yet</b></p>
            <p style={{ width: "250px", textAlign: "center", margin: "0 auto", color: "#464665", fontSize: "14px" }}>
              Start by adding a publication below
            </p>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
              <div style={boxStyling}>
                <Button
                  variant="contained"
                  style={buttonStyle}
                  onClick={() => handleAddPublicationButton()}
                >
                  <img
                    src={Note_Add}
                    alt="Icon"
                    style={iconStyle}
                  />
                  Add a Publication
                </Button>
                <p style={paraStyling}>
                  Start tracking performance of a publication already live.
                  <span style={{ marginLeft: "3px" }}>
                    <InfoToolTip text="Add a publication to track and analyze its performance within your campaign." size="12px" ></InfoToolTip>
                  </span>
                </p>
              </div>

            </div>
          </div>
        </MyPaper>
      ) : (
        <>
          <Card style={{ padding: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                {/* Total Engagement graph */}
                <h4>
                  <b className={styles.title}>Total Engagement</b>
                  &nbsp;&nbsp;
                  <InfoToolTip text="Total engagements of all influencer's posts in this campaign as per filters applied" />
                </h4>
              </Grid>
            </Grid>
            {totalEngagementTime?.length > 0 && totalEngagement?.length > 0 ? (
              <>
                <ReactEcharts
                  option={BarChart(
                    totalEngagementTime,
                    true,
                    totalEngagement,
                    platforms,
                    ["line", "bar", "stack"]
                  )}
                  style={{ height: "400px", width: "100%", borderRadius: 10 }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: 100,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.675em",
                  }}
                >
                  <b className={styles.title}>*Engagement is cumulative</b>
                  &nbsp;&nbsp;
                  <InfoToolTip
                    text={constantMessages.ENGAGEMENT_TOOLTIP}
                    style={{ marginLeft: 4, marginBottom: "1rem" }}
                  />
                </div>
                <br />
              </>
            ) : (
              <p
                style={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Card>
          <br />
          <Card style={{ padding: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                {/* Total Engagement by publication Date graph */}
                <h4>
                  <b className={styles.title}>Engagement By Publication Date </b>
                  &nbsp;&nbsp;
                  <InfoToolTip text="Displays the engagement of your publications for each Social Network over time by their Publication dates as per filters applied" />
                </h4>
              </Grid>
            </Grid>
            {publicationEngagementTime?.length > 0 &&
              publicationEngagement?.length > 0 ? (
              <>
                <ReactEcharts
                  option={BarChart(
                    publicationEngagementTime,
                    true,
                    publicationEngagement,
                    platforms,
                    ["line", "bar", "stack"]
                  )}
                  style={{ height: "400px", width: "100%", borderRadius: 10 }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: 100,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.675em",
                  }}
                >
                  <b className={styles.title}>*Engagement is cumulative</b>
                  &nbsp;&nbsp;
                  <InfoToolTip
                    text={constantMessages.ENGAGEMENT_TOOLTIP}
                    style={{ marginLeft: 4, marginBottom: "1rem" }}
                  />
                </div>
                <br />
              </>
            ) : (
              <p
                style={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No publications found within selected filters
              </p>
            )}
          </Card>
          <br />
          <h4 className={styles.title}>
            <b>Engagement Summary </b>
            &nbsp;&nbsp;
            <InfoToolTip text="Top audience metrics matching the applied filters." />
          </h4>
          <br />
          {/* Engagement summary cards based on platforms */}
          <Grid container spacing={4}>
            {platforms ? (
              platforms?.map((platform) => (
                <Grid item xs={3} key={platform}>
                  <SummaryCard
                    platformCount={engagementSummary[platform]}
                    platform={platform}
                  />
                </Grid>
              ))
            ) : (
              <p
                style={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Grid>
          <br />
          {/* Engagement and Reach split cards */}
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Card style={{ padding: "20px", height: "500px" }}>
                <h4>
                  <b className={styles.title}>Reach Split</b>
                  &nbsp;&nbsp;
                  <InfoToolTip text="Displays the reach of your publications by social network (only for Instagram, Youtube, Twitter and TikTok)." />
                </h4>

                {reachColorArray?.length > 0 && reachSplit?.length > 0 ? (
                  <ReactEcharts
                    option={DoughnutChart(
                      "Reach Split",
                      reachColorArray,
                      "vertical",
                      10,
                      50,
                      reachSplit,
                      ["40%", "70%"],
                      true,
                      false,
                      true,
                      {
                        valueFormatter: function (value) {
                          return Math.round(value) + "%";
                        },
                      }
                    )}
                    style={{ height: "400px", width: "100%", borderRadius: 10 }}
                  />
                ) : (
                  <p
                    style={{
                      height: "400px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Graph found
                  </p>
                )}
                {(platforms.includes("facebook") ||
                  platforms.includes("linkedin")) && (
                    <p className={styles.disclaimer}>
                      *This stat is not supported for Facebook & Linkedin
                    </p>
                  )}
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ padding: "20px", height: "500px" }}>
                <h4>
                  <b className={styles.title}>Engagement Split</b>
                  &nbsp;&nbsp;
                  <InfoToolTip text="Displays the engagement of your publications by social network." />
                </h4>

                {engagementColorArray?.length > 0 && engagementSplit?.length > 0 ? (
                  <ReactEcharts
                    option={DoughnutChart(
                      "Engagement Split",
                      engagementColorArray,
                      "vertical",
                      10,
                      50,
                      engagementSplit,
                      ["40%", "70%"],
                      true,
                      false,
                      true,
                      {
                        valueFormatter: function (value) {
                          return Math.round(value) + "%";
                        },
                      }
                    )}
                    style={{ height: "400px", width: "100%", borderRadius: 10 }}
                  />
                ) : (
                  <p
                    style={{
                      height: "400px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    No Graph found
                  </p>
                )}
              </Card>
            </Grid>
          </Grid>
          <br />
          {/* Listwise split of analytics */}
          <Card style={{ padding: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                <h4>
                  <b className={styles.title}>
                    Groupwise Performance On Engagement{" "}
                  </b>
                  <span className={styles.subtitleText}> (Max 3)</span>
                  &nbsp;&nbsp;
                  <InfoToolTip text="Engagement Rate of the Top 3 performing groups as per filters selected on top." />
                </h4>
              </Grid>
            </Grid>

            {listEngagementTime?.length > 0 && listEngagement?.length > 0 ? (
              <>
                <ReactEcharts
                  option={BarChart(
                    listEngagementTime,
                    true,
                    listEngagement,
                    lists,
                    ["line", "bar", "stack"]
                  )}
                  style={{ height: "400px", width: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: 100,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.675em",
                  }}
                >
                  <b className={styles.title}>*Engagement is cumulative</b>
                  &nbsp;&nbsp;
                  <InfoToolTip
                    text={constantMessages.ENGAGEMENT_TOOLTIP}
                    style={{ marginLeft: 4, marginBottom: "1rem" }}
                  />
                </div>
                <br />
              </>
            ) : (
              <p
                style={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Card>
        </>
      )}
    </>
  )

};
CampaignAnalyticsGraphs.propTypes = {
  totalEngagement: PropTypes.array.isRequired,
  totalEngagementTime: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  publicationEngagement: PropTypes.array.isRequired,
  publicationEngagementTime: PropTypes.array.isRequired,
  engagementSummary: PropTypes.array.isRequired,
  reachColorArray: PropTypes.array.isRequired,
  reachSplit: PropTypes.array.isRequired,
  engagementSplit: PropTypes.array.isRequired,
  engagementColorArray: PropTypes.array.isRequired,
  listEngagement: PropTypes.array.isRequired,
  listEngagementTime: PropTypes.array.isRequired,
  lists: PropTypes.array.isRequired,
};

export default CampaignAnalyticsGraphs;
