import React from "react";
import { Grid, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
import InviteSelection from "./InviteSelection";
import InviteLayout from "./InviteLayout";

const AddCampaignInfluencer = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [type, setType] = useState("");
  const steps = [
    "How do you want to invite the influencer?",
    "Send out those invites",
  ];

  const moveToNextStep = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const selectedValue = (value) => {
    moveToNextStep();
    setType(value);
  };

  const goback = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };

  return (
    <>
      <Box m={2} style={{ backgroundColor: "#FAFAFB", padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div style={{marginTop: "10px"}}>
                {activeStep === 0 && (
                  <InviteSelection selectedValue={selectedValue} />
                )}
                {activeStep === 1 && (
                  <InviteLayout typeOfInvite={type} goback={goback} groupList={props.groupList} campaignName={props.campaignName}/>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}


export default AddCampaignInfluencer;