import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    styled,
    TableContainer,
    TableSortLabel
} from '@mui/material';
import { WorldMap } from 'react-svg-worldmap';

const CountryMapWithTable = ({ data, mapData, backgroundColor }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'asc' });
    const sortedData = [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    return (
        <Grid container spacing={2}>
            {/* Table */}
            <Grid item xs={12} md={4}>
                <Card style={{ padding: 20, height: '100%' }}>
                    <TableContainer
                        style={{
                            maxHeight: 500,
                            overflow: 'auto',
                        }}
                    >
                        <div
                            style={{
                                maxHeight: 'inherit',
                                overflow: 'scroll',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                            className="scroll-container"
                        >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                                active={sortConfig.key === 'title'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleSort('title')}
                                            >
                                                <b>Country</b>
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableSortLabel
                                                active={sortConfig.key === 'clicks'}
                                                direction={sortConfig.direction}
                                                onClick={() => handleSort('clicks')}
                                            >
                                                <b>Clicks</b>
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.title}</TableCell>
                                            <TableCell align="right">{row.clicks}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </Card>
            </Grid>

            {/* Map */}
            <Grid item xs={12} md={8}>
                <Card
                    style={{
                        padding: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        backgroundColor: backgroundColor,
                    }}
                >
                    <div style={{ transform: 'scale(1.2)', transformOrigin: 'center' }}>
                        <WorldMap
                            color="#024e90"
                            value-suffix=" Clicks"
                            size="responsive"
                            data={mapData}
                            borderColor={'black'}
                            strokeOpacity={0.3}
                        />
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
};

CountryMapWithTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            clicks: PropTypes.number.isRequired,
            percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    chartTitle: PropTypes.string,
    parentStyle: PropTypes.object,
    country: PropTypes.bool,
    backgroundColor: PropTypes.string,
};

export default CountryMapWithTable;
