import React from "react";
import { useDispatch, useSelector } from "react-redux";
import configs from "../../../../config/main.json";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Circle, OpenInNew } from "@mui/icons-material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { tableHeaders } from "./CommonAssets";
import { contractCodes } from "../../../../utility/status";
import CampaignContractsActionButton from "./CampaignContractsActionButton";
import { useLocation, useNavigate } from "react-router-dom";
import { getCampaignName } from "../../../../utility/sessionData";
import { downloadNotification, onDownloadHandle } from "../../../../utility/exportData";
import { exportContractList, getAllContractList, getDetailedContractsForCampaign } from "../../../../services/contracts";
import { notify } from "../../../../redux/features/system/systemAlert";
import { logout } from "../../../../redux/features/user/userSlice";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import Loader from "../../../common/loader";
import CustomizedTable from "../../../common/customizedTable";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../common/StyledComponents";
import FileSaver from "file-saver";
import {getTimeZone} from "../../../../services/common";
import { stringAvatar } from "../campaignDrafts/CommonAssets";

const CampaignContractList = ({ sendFilters, filters, tabId }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("c.createdAt");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [mimeType, setMimeType] = React.useState("");
  const [campaignName] = React.useState(getCampaignName);
  const [fileName, setFileName] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const [lastRefreshed, setLastRefreshed] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(10);
  const [bodyColumns, setBodyColumns] = React.useState([]);

  const [headers, setHeaders] = React.useState(tableHeaders);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campaignId = params.get("id");
  React.useEffect(() => {
    setRefresh(false);
    getContractsList(campaignId, page, rows, sortCol, sortOrder, 0, false, mimeType, filters);
    getFullContractList();
  }, [campaignId]);

  React.useEffect(() => {
    getContractsList(campaignId, page, rows, sortCol, sortOrder, 0, false, mimeType, filters);
  }, [filters]);

  const getContractsList = async (id, page, rows, sortCol, sortOrder, exportValue, isRefresh, mimeType) => {
    const fileName = "Contract_" + campaignName;
    const timeZone = getTimeZone;
    const data = {
      cid: id,
      page: page + 1,
      rows,
      sortCol,
      sortOrder,
      isExport: exportValue,
      isRefreshed: isRefresh,
      mimeType,
      filters: {
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        template: filters?.template,
        status: filters?.status,
      },
      campaignName,
      account: user.agencyId,
      client: user.clientId,
      path: "Campaigns.Fields.Contracts",
      timezone: timeZone,
    };
    if (exportValue === 1) {
      downloadNotification(dispatch);
      setFileName(fileName);
      const downloadData = await getDetailedContractsForCampaign(data, user, dispatch);
      if (downloadData.response !== undefined) {
        onDownloadHandle(new Blob([downloadData.response]), fileName + "." + mimeType);
      } else {
        dispatch(
          notify({
            message: "No data to download",
            type: "error",
          })
        );
      }
    } else {
      setDataFound(false);
      setLoading(true);
      const contracts = await getDetailedContractsForCampaign(data, user, dispatch);
      setLoading(false);
      console.log(contracts.response)
      if (contracts.response?.records !== null &&  contracts.response?.records?.length > 0) {
        setLastRefreshed(contracts.response.lastRefreshed);
        setTotalRows(contracts.response.total_records);
        createData(contracts.response.records);
        setDataFound(true);
        setFiltersBasedOnData(contracts.response.records);
      } else {
        setDataFound(false);
        sendFilters({ contractName: [], contractDate: [], contractStatus: [] });
      }
    }
  };
  const setFiltersBasedOnData = (records) => {
    const contractName = [...new Set(records.map((contract) => contract.title))];
    const contractDate = [
      ...new Set(records.map((contract) => getFormattedDate(contract.createdAt, "Do MMMM YYYY"))),
    ];
    const contractStatus = [
      ...new Set(
        records.map((contract) => {
          if (contract.accepted) {
            return "Accepted";
          } else if (!contract.accepted && contract.timeDiff < 0) {
            return "Invitation Expired";
          } else {
            return "Pending";
          }
        })
      ),
    ];
    sendFilters({ contractName, contractDate, contractStatus });
  };
  const getFullContractList = async () => {
    const list = await getAllContractList(campaignId, user, dispatch);
    if (list.response?.length > 0) {
      const contractName = [...new Set(list.response?.map((contract) => contract.title))];
      const contractDate = [...new Set(list.response?.map((contract) => getFormattedDate(contract.createdAt, "Do MMMM YYYY")))];
      const contractStatus = [
        ...new Set(
          list.response?.map((contract) => {
            if (contract.accepted) {
              return "Accepted";
            } else if (!contract.accepted && contract.timeDiff < 0) {
              return "Invitation Expired";
            } else {
              return "Pending";
            }
          })
        ),
      ];
      sendFilters({ contractName, contractDate,contractStatus });
    } else {
      sendFilters({ contractName: [], contractStatus: [] });
    }
  };

  //change the page or no of rows
  const sendInformation = (event) => {
    setPage(event.page);
    setRows(event.rowsPerPage);
    setSortOrder(event.order);
    setSortCol(event.orderBy);
    setMimeType(event.mimeType);
    setFileName(event.fileName);
    getContractsList(campaignId, event.page, event.rowsPerPage, event.orderBy, event.order, event.export, false, event.mimeType, filters);
  };

  //create table data from influencer apis
  const createData = (records) => {
    const columns = [];
    records.forEach((data) => {
      let object = {
        name: (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2} style={{ marginBlock: "auto", marginRight: "1vw" }}>
              <Avatar
                variant="circle"
                src={data.photoUrl || ''}
                {...stringAvatar(data.infName)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <p className={styles.bodycell}>
                <b style={{ fontSize: "16px" }}>{data.infName}</b>
                {data.profileUrl !== "" && (
                  <a href={data.profileUrl} target="_blank" rel="noreferrer" style={{ marginLeft: "10px" }}>
                    <OpenInNew fontSize="small" />
                  </a>
                )}
              </p>
              <p className={styles.bodycell}>
                <i style={{ fontSize: "12px" }}>{data.email}</i>
              </p>
            </Grid>
            <Grid item xs={1}>
              <Grid container>
                {
                  <Grid item xs={2} className={styles.bodycell}>
                    <Typography sx={textColor[data.platform]} style={{ marginTop: 10 }}>
                      {snapshotHeaderIcons[data.platform]}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        ),
        template: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {data.title}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        sentOn: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {getFormattedDate(data.createdAt, "Do MMMM YYYY")}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        status: (
          <p className={styles.bodycell}>
            <Grid container spacing={1}>
              {data.accepted && (
                <Grid item xs={10} className={styles.bodycell}>
                  <span style={{ fontSize: "14px" }}>
                    <Circle
                      htmlColor={contractCodes.accepted.color}
                      style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                    />
                    &nbsp;&nbsp;
                    <b>Accepted</b>
                  </span>
                  <br />
                  <span style={{ fontSize: "12px", marginLeft: "27px" }}>
                    <i>
                      Accepted On <b>{getFormattedDate(data.acceptedOn, "Do MMMM YYYY")}</b>
                    </i>
                  </span>
                </Grid>
              )}
              {!data.accepted && data.timeDiff < 0 && (
                <Grid item xs={10} className={styles.bodycell}>
                  <span style={{ fontSize: "14px" }}>
                    <Circle
                      htmlColor={contractCodes.expired.color}
                      style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                    />
                    &nbsp;&nbsp;
                    <b>Invitation Expired</b>
                  </span>
                </Grid>
              )}
              {!data.accepted && data.timeDiff >= 24 && (
                <Grid item xs={10} className={styles.bodycell}>
                  <span style={{ fontSize: "14px" }}>
                    <Circle
                      htmlColor={contractCodes.pending.color}
                      style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                    />
                    &nbsp;&nbsp;
                    <b>Pending</b>
                  </span>
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    {Math.floor(data.timeDiff / 24) === 1 ? (
                      <i>Pending (1 day left)</i>
                    ) : (
                      <i>Pending ({Math.floor(data.timeDiff / 24)} days left)</i>
                    )}
                  </span>
                </Grid>
              )}
              {!data.accepted && data.timeDiff < 24 && data.timeDiff > 0 && (
                <Grid item xs={10} className={styles.bodycell}>
                  <span style={{ fontSize: "14px" }}>
                    <Circle
                      htmlColor={contractCodes.pending.color}
                      style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                    />
                    &nbsp;&nbsp;
                    <b>Pending</b>
                  </span>
                  <br />
                  <span style={{ fontSize: "12px" }}>
                    {data.timeDiff === 1 ? (
                      <i>Pending (1 hour left)</i>
                    ) : (
                      <i>
                        Pending <b>({data.timeDiff} hours left)</b>
                      </i>
                    )}
                  </span>
                </Grid>
              )}
              <Grid item xs={2} className={styles.bodycell}></Grid>
            </Grid>
          </p>
        ),
        action: (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CampaignContractsActionButton contractDetails={data} campaignId={campaignId} campaignName={campaignName} />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const exportCSV = async () => {
    dispatch(
      notify({
        message: "Preparing your file to download",
        type: "info",
      })
    );
    const response = await getAllContractList(campaignId, user, dispatch);
    const contractList = response?.response;
    let tableData = [];
    if (contractList !== null) {
      tableData = contractList?.map((contract, index) => {
        const data = { ...contract };
        if (contract.accepted) {
          data["status"] = "Accepted";
        } else {
          if (contract.timeDiff < 0) {
            data["status"] = "Invite Expired";
          } else {
            if (contract.timeDiff >= 24) {
              const days = Math.floor(contract.timeDiff / 24);
              if (days === 1) data["status"] = "Pending (" + days + " day left)";
              else data["status"] = "Pending (" + days + " days left)";
            } else {
              const hours = contract.timeDiff;
              if (hours === 1) data["status"] = "Pending (" + hours + " hour left)";
              else data["status"] = "Pending (" + hours + " hours left)";
            }
          }
        }

        data["influencer"] = {
          name: contract.infName,
          email: contract.email,
        };

        return data;
      });
    }
    const iids = [];
    for (let i = 0; i < contractList?.length; i++) {
      iids.push(contractList[i].iid);
    }
    const uniqueIids = iids?.filter((value, index, self) => self.indexOf(value) === index);

    const body = {
      ids: uniqueIids.join(","),
    };
    const csvData = await exportContractList(body, user, dispatch);
    const paymentDetails = csvData.response.paymentDetails !== null ? csvData.response.paymentDetails : [];
    const csvHeader = [
      "Name",
      "List",
      "Template Name",
      "Sent On",
      "Status",
      "Age",
      "Document/Id Number",
      "Currency",
      "Payment Type",
      "Payment Name",
      "Account Number",
      "Bank Name",
      "Branch Name",
      "Swift",
      "Country",
      "Phone Number",
      "Card Number",
      "Card Name",
    ];
    let csvRes = csvHeader.join(",") + "\n";

    tableData.forEach((row) => {
      let paymentData = {};

      for (let i = 0; i < paymentDetails?.length; i++) {
        if (row.iid === paymentDetails[i].iid) {
          paymentData = paymentDetails[i];
          break;
        }
      }

      let rowData = [];
      rowData.push(row.infName);
      rowData.push(row.listName);
      rowData.push(row.title);
      rowData.push(row.sentOn);
      rowData.push(row.status);
      rowData.push(paymentData.age !== 0 ? paymentData.age : "");
      rowData.push(paymentData.documentId ? `=TRIM("` + paymentData.documentId + `")` : "");
      rowData.push(paymentData.Currency || "");
      rowData.push(paymentData.paymentType || "");
      rowData.push(paymentData.paymentName || "");
      rowData.push(paymentData.accountNumber ? `=TRIM("` + paymentData.accountNumber + `")` : "");
      rowData.push(paymentData.bankName || "");
      rowData.push(paymentData.branchName || "");
      rowData.push(paymentData.swift || "");
      rowData.push(paymentData.country || "");
      rowData.push(paymentData.phoneNumber ? `=TRIM("` + paymentData.phoneNumber + `")` : "");
      rowData.push(paymentData.cardNumber ? `=TRIM("` + paymentData.cardNumber + `")` : "");
      rowData.push(paymentData.cardName || "");

      csvRes += rowData.join(",") + "\n";
    });

    const blob = new Blob([csvRes], { type: "text/csv" });
    FileSaver(blob, campaignName + "_Payment Details");
    dispatch(
      notify({
        message: `${campaignName + "_Payment Details"} has been downloaded`,
        type: "success",
      })
    );
  };

  const sendContract = () => {
    navigate(`/app/campaigns/influencers/contracts/send?id=${campaignId}&tabId=${tabId}`);
  };
  return (
    <Box>
      <Grid container spacing={5} style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
        <Grid item>
          <div>
            <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
              <StyledStaticButton value="active" className={styles.staticButton} onClick={exportCSV}>
                <b>Export CSV</b>
              </StyledStaticButton>
            </StyledStaticButtonGroup>
          </div>
        </Grid>
        <Grid item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
              <StyledStaticButton value="active" className={styles.staticButton} onClick={sendContract}>
                <b>Send Contract</b>
              </StyledStaticButton>
            </StyledStaticButtonGroup>
          </div>
        </Grid>
      </Grid>
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <div className={styles.background}>
            <p>
              Showing {bodyColumns?.length} of {totalRows} contracts{" "}
            </p>
            <CustomizedTable
              tableHeaders={headers}
              bodyColumns={bodyColumns}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
              mimeType={mimeType}
              fileName={fileName}
              sendInformation={sendInformation}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.child}> No contracts added on this campaign</div>
          </div>
        </>
      )}
    </Box>
  );
};

export default CampaignContractList;
