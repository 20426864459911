import { Grid } from "@mui/material";

export const tableHeaders = [
  {
    id: 1,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Comment
        </Grid>
      </Grid>
    ),
    mappedValue: "Comment",
    field: "comment",
    headeralignment: "center",
    bodyalignment: "center",
    sortable: false,
    show: true,
  },
  {
    id: 2,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Sentiment
        </Grid>
      </Grid>
    ),
    mappedValue: "Sentiment",
    field: "sentiment",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  },
  {
    id: 3,
    label: (
      <Grid container spacing={1}>
        <Grid item xs={10}>
          Score
        </Grid>
      </Grid>
    ),
    mappedValue: "Score",
    field: "score",
    headeralignment: "left",
    bodyalignment: "left",
    sortable: false,
    show: true,
  }
];
