import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { Box, Grid, Tooltip } from "@mui/material";
import { Circle, Public } from "@mui/icons-material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { templatetableHeaders } from "./CommonAssets";
import { contractCodes } from "../../../../utility/status";
import { useLocation, useNavigate } from "react-router-dom";
import { getCampaignName } from "../../../../utility/sessionData";
import { downloadNotification, onDownloadHandle } from "../../../../utility/exportData";
import { getAllContractTemplates, getContractTemplates } from "../../../../services/contracts";
import { notify } from "../../../../redux/features/system/systemAlert";
import { logout } from "../../../../redux/features/user/userSlice";
import Loader from "../../../common/loader";
import CustomizedTable from "../../../common/customizedTable";
import CampaignContractsTemplateActionButton from "./CampaignContractsTemplateActionButton";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../common/StyledComponents";
import { getTimeZone } from "../../../../services/common";

const CampaignContractTemplates = ({ sendFilters, filters, tabId }) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(10);
  const [sortCol, setSortCol] = React.useState("updatedAt");
  const [sortOrder, setSortOrder] = React.useState("desc");
  const [filter, setFilter] = React.useState("");
  const [mimeType, setMimeType] = React.useState("");
  const [campaignName] = React.useState(getCampaignName);
  const [fileName, setFileName] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);
  const [lastRefreshed, setLastRefreshed] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(10);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [headers, setHeaders] = React.useState(templatetableHeaders);
  const dispatch = useDispatch();
  const campaignId = params.get("id");

  React.useEffect(() => {
    setRefresh(false);
    getFullContractTemplateList();
    getTemplates(campaignId, page, rows, sortCol, sortOrder, 0, false, mimeType, filters);
  }, [campaignId]);

  const getTemplates = async (id, page, rows, sortCol, sortOrder, exportValue, isRefresh, mimeType, filters) => {
    const timeZone = getTimeZone();
    const fileName = "Contract_" + campaignName;
    const data = {
      cid: id,
      page: page + 1,
      rows,
      sortCol,
      sortOrder,
      isExport: exportValue,
      isRefreshed: isRefresh,
      mimeType,
      campaignName,
      filters: filters,
      account: user.agencyId,
      client: user.clientId,
      path: "Campaigns.Fields.Contracts",
      timeZone: timeZone,
    };
    if (exportValue === 1) {
      downloadNotification(dispatch);
      setFileName(fileName);
      const downloadData = await getContractTemplates(data, user, dispatch);
      if (downloadData.response !== undefined) {
        onDownloadHandle(new Blob([downloadData.response]), fileName + "." + mimeType);
      } else {
        dispatch(
          notify({
            message: "No data to download",
            type: "error",
          })
        );
      }
    } else {
      setDataFound(false);
      setLoading(true);
      const contracts = await getContractTemplates(data, user, dispatch);
      setLoading(false);
      if (contracts.status === true && contracts.response?.records?.length > 0) {
        setLastRefreshed(contracts.response.lastRefreshed);
        setTotalRows(contracts.response.total_records);
        createData(contracts.response.records);
        setDataFound(true);
        setFiltersBasedOnData(contracts.response.records);
      } else {
        setDataFound(false);
        if (contracts.response?.message?.includes("expired")) {
          dispatch(logout());
        }
        dispatch(
          notify({
            message: contracts.response?.message,
            type: "error",
          })
        );
      }
    }
  };
  const setFiltersBasedOnData = (records) => {
    const contractName = [...new Set(records.map((template) => template.title))];
    const contractDate = [
      ...new Set(records.map((template) => getFormattedDate(template.updatedAt, "Do MMMM YYYY"))),
    ];
    const contractStatus = [...new Set(records.map((template) => (template.isUse ? "Used" : "Not Used")))];
    sendFilters({ contractName, contractDate, contractStatus });
  };

  React.useEffect(() => {
    getTemplates(campaignId, page, rows, sortCol, sortOrder, 0, false, mimeType, filters);
  }, [filters]);

  const getFullContractTemplateList = async () => {
    const list = await getAllContractTemplates(campaignId, user, dispatch);
    if (list.response?.length > 0) {
      const contractName = [...new Set(list.response?.map((contract) => contract.title))];
      const contractDate = [...new Set(list.response?.map((contract) => getFormattedDate(contract.updatedAt, "Do MMMM YYYY")))];
      const contractStatus = [
        ...new Set(
          list.response?.map((contract) => {
            if (contract.isUse) {
              return "Used";
            } else {
              return "Not Used";
            }
          })
        ),
      ];
      sendFilters({ contractName, contractDate, contractStatus });
    }
  };

  //change the page or no of rows
  const sendInformation = (event) => {
    setPage(event.page);
    setRows(event.rowsPerPage);
    setSortOrder(event.order);
    setSortCol(event.orderBy);
    setMimeType(event.mimeType);
    setFileName(event.fileName);
    getTemplates(campaignId, event.page, event.rowsPerPage, event.orderBy, event.order, event.export, false, event.mimeType);
  };

  const reload = () => {
    getTemplates(campaignId, page, rows, sortCol, sortOrder, 0, false, mimeType, filters);
  };

  React.useEffect(() => {
    if (refresh) {
      reload();
    }
  }, [refresh]);

  //create table data from influencer apis
  const createData = (records) => {
    const columns = [];
    records.forEach((data) => {
      let object = {
        name: (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2} className={styles.bodycell}>
              {data.cid === -1 && (
                <Tooltip title={"Global template for this account"}>
                  <Grid item xs={10} className={styles.bodycell}>
                    <Public fontSize="small" />
                  </Grid>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={10}>
              <p className={styles.bodycell}>
                <b style={{ fontSize: "16px" }}>{data.title}</b>
              </p>
            </Grid>
          </Grid>
        ),
        description: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {data.description}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        lastUpdated: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {getFormattedDate(data.updatedAt, "Do MMMM YYYY")}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        used: (
          <>
            {" "}
            <p className={styles.bodycell}>
              <Grid container spacing={1}>
                {data.isUse ? (
                  <Grid item xs={10} className={styles.bodycell}>
                    <span style={{ fontSize: "14px" }}>
                      <Circle
                        htmlColor={contractCodes.accepted.color}
                        style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                      />
                      &nbsp;&nbsp;
                      <b>Yes</b>
                    </span>{" "}
                  </Grid>
                ) : (
                  <Grid item xs={10} className={styles.bodycell}>
                    <span style={{ fontSize: "14px" }}>
                      <Circle
                        htmlColor={contractCodes.expired.color}
                        style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
                      />
                      &nbsp;&nbsp;
                      <b>No</b>
                    </span>{" "}
                  </Grid>
                )}

                <Grid item xs={2} className={styles.bodycell}></Grid>
              </Grid>
            </p>
          </>
        ),

        action: (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CampaignContractsTemplateActionButton
                templateDetails={data}
                campaignId={campaignId}
                campaignName={campaignName}
                tabId={tabId}
                reload={() => setRefresh(true)}
              />
            </Grid>
          </Grid>
        ),
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const createNewTemplate = () => {
    navigate(`/app/campaigns/influencers/contracts/create?id=${campaignId}&tabId=${tabId}`);
  };

  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "20px" }}>
        <StyledStaticButtonGroup color="primary" size="small" exclusive aria-label="Campaign">
          <StyledStaticButton value="active" className={styles.staticButton} onClick={createNewTemplate}>
            <b>+ New Template</b>
          </StyledStaticButton>
        </StyledStaticButtonGroup>
      </div>
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <div className={styles.background}>
            <p>
              Showing {page * rows + 1}-{Math.min((page + 1) * rows, totalRows)} of {totalRows} contracts
            </p>
            <CustomizedTable
              tableHeaders={headers}
              bodyColumns={bodyColumns}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sortOrder={sortOrder}
              sortOrderBy={sortCol}
              mimeType={mimeType}
              fileName={fileName}
              sendInformation={sendInformation}
            />
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.child}> No templates added on this campaign</div>
        </div>
      )}
    </Box>
  );
};

export default CampaignContractTemplates;
