import React, { Suspense, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "./components/common/PageWrapper";
import EngagementReach from "./components/pages/EngagementReach";
import { Box } from "@mui/material";
import ErrorBoundary from "../../../../components/common/ErrorBoundary";
import ReachByGender from "./components/pages/ReachByGender";
import ReachByAgeRange from "./components/pages/ReachByAgeRange";

// lazy loaded compoents
const ReachByWeek = React.lazy(() => import("./components/pages/ReachByWeek"));
const ReachByLocation = React.lazy(() =>
  import("./components/pages/ReachByLocation")
);
const ReachByPlatform = React.lazy(() =>
  import("./components/pages/ReachByPlatform")
);
const InfluencersEngagement = React.lazy(() =>
  import("./components/pages/InfluencersEngagement")
);

const dropdownData = [
  { id: 1, name: "Engagement vs Reach" },
  { id: 2, name: "Reach By Week" },
  { id: 3, name: "Engagement by Week" },
  { id: 4, name: "Engagement by Location" },
  { id: 5, name: "Reach by Location" },
  { id: 6, name: "Engagement by Gender" },
  { id: 7, name: "Reach by Gender" },
  { id: 8, name: "Engagement by Age Range" },
  { id: 9, name: "Reach by Age Range" },
  { id: 10, name: "Engagement By Platform" },
  { id: 11, name: "Reach by Platform" },
  { id: 12, name: "Engagement By Post Type" },
  { id: 13, name: "Reach By Post Type" },
  { id: 14, name: "Influencers" },
  // { id: 10, name: "Map Representation" }
];

const AdvanceAnalytics = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(dropdownData[0]);
  const [lastRefreshedTime, setLastRefreshedTime] = useState("");
  const [nextRefreshTime, setNextRefreshTime] = useState("");

  const previousClickHandler = () => {
    const selectedItem = dropdownData.find(
      (item) => currentSection.id === item.id
    );
    if (selectedItem.id === 1) {
      return;
    }
    setCurrentSection(dropdownData[selectedItem.id - 2]);
    scrollToTop();
  };

  const scrollToTop = () =>
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

  const nextClickHandler = () => {
    const selectedItem = dropdownData.find(
      (item) => currentSection.id === item.id
    );
    if (selectedItem.id === dropdownData?.length) {
      return;
    }
    setCurrentSection(dropdownData[selectedItem.id]);
    scrollToTop();
  };

  const getLastRefreshedTime = (event) => {
    setLastRefreshedTime(event);
  };

  const getNextRefreshTime = (event) => {
    setNextRefreshTime(event);
  };

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const renderSectionHander = () => {
    const campaignId = +params.get("id");
    switch (currentSection.id) {
      case 1:
        return (
          <EngagementReach
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 2:
        return (
          <ReachByWeek
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 3:
        return (
          <ReachByWeek
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            isEngagementInfo
          />
        );
      case 4:
        return (
          <ReachByLocation
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            isEngagementInfo
          />
        );
      case 5:
        return (
          <ReachByLocation
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 6:
        return (
          <ReachByGender
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            isEngagementInfo
          />
        );
      case 7:
        return (
          <ReachByGender
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 8:
        return (
          <ReachByAgeRange
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            isEngagementInfo
          />
        );
      case 9:
        return (
          <ReachByAgeRange
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 10:
        return (
          <ReachByPlatform
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            isEngagementInfo
          />
        );
      case 11:
        return (
          <ReachByPlatform
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      case 12:
        return (
          <ReachByPlatform
            isPostType={true}
            isEngagementInfo
            engPerPost
            user={user}
            dispatch={dispatch}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            campaignId={campaignId}
          />
        );
      case 13:
        return (
          <ReachByPlatform
            isPostType={true}
            user={user}
            dispatch={dispatch}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
            campaignId={campaignId}
          />
        );
      case 14:
        return (
          <InfluencersEngagement
            user={user}
            dispatch={dispatch}
            campaignId={campaignId}
            getLastRefreshedTime={getLastRefreshedTime}
            getNextRefreshTime={getNextRefreshTime}
          />
        );
      // case 10: return <MapReperesentation user={user} dispatch={dispatch} campaignId={campaignId} />;

      default:
        return <> No Information available </>;
    }
  };

  return (
    <Box maxHeight={"100%"} overflow={"hidden"}>
      <PageWrapper
        title={currentSection.name}
        lastRefreshedTime={lastRefreshedTime}
        nextRefreshTime={nextRefreshTime}
        sectionSwtichData={{
          dropdownData,
          currentSection,
          setCurrentSection,
          nextClickHandler,
          previousClickHandler,
        }}
      >
        <ErrorBoundary>
          <Suspense fallback="loading data...">
            {renderSectionHander()}
          </Suspense>
        </ErrorBoundary>
      </PageWrapper>
    </Box>
  );
};

export default AdvanceAnalytics;
