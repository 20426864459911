import { Box, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { func } from "prop-types";
const commonStyle = {
  cursor: "pointer",
  backgroundColor: "#FFFFFF",
  padding: "20px",
  border: "1px solid black",
  height: "250px",
  transition: "background-color 0.3s",
};

const InviteSelection = ({ selectedValue }) => {
  const handleChange = (value) => {
    selectedValue(value);
  };
  return (
    <>
      <FormControl style={{ width: "100%", height: "550px", display: "flex", justifyContent: "center", alignItems: "center", }}>
        <Grid container spacing={8}>
          <Grid item xs={6} style={{ cursor: "pointer" }} onClick={() => handleChange("list")}>
            <Box
              m={3}
              style={{
                ...commonStyle,
                "&:hover": {
                  backgroundColor: "#CCCCCC",
                },
              }}
            >
              <h2 style={{ fontSize: "34px", fontWeight: 700, paddingTop: "30px", textAlign: "left" }}>Invite From Groups</h2>
              <h3 style={{ fontSize: "18px", fontWeight: 300, paddingTop: "20px", textAlign: "left" }}>
              Use this option to extend invitations to Influencers from an existing influencer group.
              </h3>
            </Box>
          </Grid>
          <Grid item xs={6} style={{ cursor: "pointer" }} onClick={() => handleChange("email")}>
            <Box
              m={3}
              style={{
                ...commonStyle,
                "&:hover": {
                  backgroundColor: "#CCCCCC",
                },
              }}
            >
              <h2 style={{ fontSize: "34px", fontWeight: 700, paddingTop: "30px", textAlign: "left" }}>Invite via Email</h2>
              <h3 style={{ fontSize: "18px", fontWeight: 300, paddingTop: "20px", textAlign: "left" }}>
              Use this option to invite influencers via email if you have their email addresses available.
              </h3>
            </Box>
          </Grid>
        </Grid>
        <br />
        <br />
      </FormControl>
    </>
  );
};

InviteSelection.propTypes = {
  selectedValue: func.isRequired,
};

export default InviteSelection;
