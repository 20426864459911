import { Box, Grid } from "@mui/material";

import AnalyticsFilter from "./AnalyticsFilter";
import { snapshotHeaderIcons, textColor } from "../../../../utility/plaformIcons";
import React from "react";
import { useLocation } from "react-router-dom";
import { getEnagementGraph, getListEngagementGraph, getPublicationGraph } from "../../../../services/campaignAnalytics";
import { capitalizeFirstLetter } from "../../../../utility/stringOperations";
import moment from "moment";
import Loader from "../../../common/loader";
import CampaignAnalyticsGraphs from "./CampaignAnalyticsGraphs";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import AdjustCampaignDates from "../../../common/AdjustCampaignDates";
import DummyCampaign from "../../../common/DummyCampaign";

const CampaignAnalyticsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = React.useState(false);
  const [dataFound, setDataFound] = React.useState(false);

  const [totalEngagement, setTotalEngagement] = React.useState([]); //total engagement of all influencer posts
  const [totalEngagementTime, setTotalEngagementTime] = React.useState([]);

  const [publicationEngagement, setPublicationEngagement] = React.useState([]); //engagment by publication date
  const [publicationEngagementTime, setPublicationEngagementTime] = React.useState([]);

  const [listEngagement, setListEngagement] = React.useState([]); //engagement by top 3 lists
  const [listEngagementTime, setListEngagementTime] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [platforms, setPlatforms] = React.useState([]);
  const [engagementSummary, setEngagementSummary] = React.useState({}); //engagement summary pf all platforms
  const [reachSplit, setReachSplit] = React.useState([]); //reach split over social network
  const [reachColorArray, setReachColorArray] = React.useState([]); //engagement split over social network
  const [engagementSplit, setEngagementSplit] = React.useState([]);
  const [engagementColorArray, setEngagementColorArray] = React.useState([]);
  
  const [startDate, setStartDate] = React.useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD")); // filter of date //6 months ago
  const [endDate, setEndDate] = React.useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(Object.keys(snapshotHeaderIcons)); //filter of selected platforms. Selected all by default
  const [selectedBreakdown, setSelectedBreakdown] = React.useState("day"); //filter of day, month, week breakdown
  const [selectedGroups, setSelectedGroups] = React.useState([]); //filter of selected groups
  const [selectedInfluencers, setSelectedInfluencers] = React.useState([]); //filter of selected influencers
  const universal = params.get("universal");
  React.useEffect(() => {
    getAnalyticsData(params.get("id"), startDate, endDate, selectedBreakdown, selectedPlatforms, [-1], [-1], [-1]);
  }, [+params.get("id")]);

  const getAnalyticsData = async (cid, from, to, filterBy, plid, postTypes, lids, iids) => {
    setLoading(true);
    const engagement = await getEnagementGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch); //total engagement of all influencer posts, reach split and engagement split
    const publication = await getPublicationGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch); //engagment by publication date
    const listwiseEngagement = await getListEngagementGraph(cid, from, to, filterBy, plid, postTypes, lids, iids, user, dispatch); //engagement by top 3 groups
    setLoading(false);
    setDataFound(false);
    if (engagement.status === true) {
      if (engagement.response.TotalEngagement !== null) {
        createEngagementSummaryArray(engagement.response.TotalEngagement);
      } else {
        createEngagementSummaryArray({});
      }
      //platform wise engagement graph
      if (engagement.response.Summary !== null) {
        const platforms = Object.keys(engagement.response.Summary);
        setPlatforms(platforms);
        //engagement summary for likes, comments and shares
        setEngagementSummary(engagement.response.Summary);
      } else {
        setPlatforms([]);
        setEngagementSummary({});
      }
      if (engagement.response.ReachSplit !== null) {
        //doughnut graph for reach split
        createReachSplitArray(engagement.response.ReachSplit);
      } else {
        createReachSplitArray({});
      }
      if (engagement.response.EngagementSplit !== null) {
        //doughnut graph for engagement split
        createEngagementSplitArray(engagement.response.EngagementSplit);
      } else {
        createEngagementSplitArray({});
      }

      setDataFound(true);
    }

    if (publication.status === true && publication.response.PublicationEngagement !== null) {
      //publication summary graph
      createPublicationEngagementSummaryArray(publication.response.PublicationEngagement);
      setDataFound(true);
    } else {
      createPublicationEngagementSummaryArray({});
    }
    if (Object.keys(listwiseEngagement.response.ListEngagement)?.length > 0) {
      //listwise summary graph
      createListWisengagementSummaryArray(listwiseEngagement.response.ListEngagement);
      setDataFound(true);
    } else {
      createListWisengagementSummaryArray({});
    }
  };

  const createEngagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const platforms = Object.keys(summarySplit);
      let event = createPlatformSplitArray(platforms, summarySplit, true);
      setTotalEngagementTime(event.finalDateArray);

      setTotalEngagement(event.seriesArray);
    }
  };

  const createPublicationEngagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const platforms = Object.keys(summarySplit);
      let event = createPlatformSplitArray(platforms, summarySplit, true);
      setPublicationEngagementTime(event.finalDateArray);
      setPublicationEngagement(event.seriesArray);
    }
  };

  const createListWisengagementSummaryArray = (summarySplit) => {
    if (summarySplit) {
      const groups = Object.keys(summarySplit);
      let event = createPlatformSplitArray(groups, summarySplit, false);
      setListEngagementTime(event.finalDateArray);
      setLists(groups);
      setListEngagement(event.seriesArray);
    }
  };

  const createPlatformSplitArray = (summaryObject, summarySplit, color) => {
    let dateArray = [];
    let seriesArray = [];
    summaryObject.forEach((platform) => {
      dateArray.push(...Object.keys(summarySplit[platform]));
    });

    summaryObject.forEach((platform) => {
      const object = {
        name: platform,
        type: "bar",
        showBackground: false,
        color: color ? [textColor[platform].color] : [],
        data: [],
      };
      dateArray.forEach((date) => {
        if (summarySplit[platform][date]) {
          object.data.push(summarySplit[platform][date]);
        } else {
          object.data.push(0);
        }
      });
      seriesArray.push(object);
    });
    const finalDateArray = [...new Set(dateArray)];
    finalDateArray.sort(function (a, b) {
      return new Date(a) - new Date(b);
    });
    return { finalDateArray, seriesArray };
  };

  const createReachSplitArray = (reachSplit) => {
    const platforms = Object.keys(reachSplit);
    const splitArray = [];
    const colorArray = [];
    platforms?.map((platform) => {
      const reachPercent = ((reachSplit[platform] / reachSplit["total"]) * 100).toFixed(2);
      if (platform !== "total" && reachSplit[platform] > 0) {
        splitArray.push({
          value: reachPercent,
          name: capitalizeFirstLetter(platform),
        });
        colorArray.push(textColor[platform].color);
      }
    });
    setReachColorArray(colorArray);

    setReachSplit(splitArray);
  };

  const createEngagementSplitArray = (engagementSplit) => {
    const platforms = Object.keys(engagementSplit);
    const splitArray = [];
    const colorArray = [];
    platforms?.map((platform) => {
      const engagementPercent = ((engagementSplit[platform] / engagementSplit["total"]) * 100).toFixed(2);

      if (platform !== "total" && engagementSplit[platform] > 0) {
        splitArray.push({
          value: engagementPercent,
          name: capitalizeFirstLetter(platform),
        });
        colorArray.push(textColor[platform].color);
      }
    });
    setEngagementColorArray(colorArray);

    setEngagementSplit(splitArray);
  };

  const recieveSelectedValues = (event) => {
    setDataFound(false);
    setStartDate(event.startDate);
    setEndDate(event.endDate);
    setSelectedBreakdown(event.selectedBreakdown);
    setSelectedPlatforms(event.selectedPlatforms);
    setSelectedGroups(event.selectedGroups);
    setSelectedInfluencers(event.selectedInfluencers);
    getAnalyticsData(
      params.get("id"),
      event.startDate,
      event.endDate,
      event.selectedBreakdown,
      event.selectedPlatforms,
      [-1],
      event.selectedGroups?.length > 0 ? event.selectedGroups?.map((group) => group.id) : [-1],
      event.selectedInfluencers?.length > 0 ? event.selectedInfluencers?.map((influencer) => influencer.Iid) : [-1]
    );
  };

  return (
    <Box>
      <DummyCampaign universal={universal} />
      <AdjustCampaignDates />

      {/* Analytics filter */}
      <AnalyticsFilter
        recieveSelectedValues={recieveSelectedValues}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        breakdown={selectedBreakdown}
        platformIds={selectedPlatforms}
        selectedGroupsData={selectedGroups}
        selectedInfluencersData={selectedInfluencers}
      />
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <CampaignAnalyticsGraphs
          totalEngagement={totalEngagement}
          totalEngagementTime={totalEngagementTime}
          platforms={platforms}
          publicationEngagement={publicationEngagement}
          publicationEngagementTime={publicationEngagementTime}
          engagementSummary={engagementSummary}
          reachColorArray={reachColorArray}
          reachSplit={reachSplit}
          engagementSplit={engagementSplit}
          engagementColorArray={engagementColorArray}
          listEngagement={listEngagement}
          listEngagementTime={listEngagementTime}
          lists={lists}
        />
      ) : (
        <>
          <Grid container spacing={2}>
            {/* No campaigns found for this account */}
            <Grid item xs={12}>
              <div className={styles.container}>
                <div className={styles.child}>This campaign has no analytics for the selected range.</div>
              </div>{" "}
            </Grid>
          </Grid>
        </>
      )}
      <br />
    </Box>
  );
};

export default CampaignAnalyticsSnapshot;
