import React, { useState } from "react";
import { PropTypes } from "prop-types";
import ReactEcharts from "echarts-for-react";
import { BarChart, DoughnutChart } from "../../../common/chart";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AudienceProgressChart from "../../../common/AudienceProgressChart";
import CountryMapWithTable from "../../../common/CountryMapWithTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import { Button } from "react-bootstrap";

const CampaignLinkGraphs = ({
  linkClicksY,
  linkClicksX,
  devicesData,
  referresData,
  locationData,
  worldData,
  cityData,
  osData,
  browserData
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleDetailsClick = (type) => {
    setModalTitle(type === "countries" ? "Top Countries" : "Top Cities");
    setModalContent(type);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent(null);
    setModalTitle("");
  };
  const transformedLocationData = locationData.map((item) => ({
    name: item.title,
    value: item.clicks,
  }));

  const transformedCityData = cityData.map((item) => ({
    name: item.title,
    value: item.clicks,
  }));
  return (
    <>
      <Grid container spacing={2}>
        {/* First Row: Total Clicks and Devices */}
        <Grid item xs={12}>
          <Card style={{ padding: 20 }}>
            <h4>
              <b className={styles.title}>Total Clicks</b>
            </h4>
            {linkClicksY?.length > 0 && linkClicksX?.length > 0 ? (
              <ReactEcharts
                option={BarChart(linkClicksX, true, [
                  {
                    data: linkClicksY,
                    type: "bar",
                    color: "#4FD1C5",
                    barCategoryGap: "60%",
                  },
                ])}
                style={{ height: "400px", width: "100%" }}
              />
            ) : (
              <p
                style={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>
                <b className={styles.title}>Top Countries</b>
              </h4>
              <Button
                onClick={() => handleDetailsClick("countries")}
                style={{
                  background: locationData?.length > 0 ? "#1587FF" : "#CCCCCC",
                  borderRadius: "8px",
                  cursor: locationData?.length > 0 ? "pointer" : "not-allowed",
                }}
                disabled={!locationData?.length}
              >
                Expand
              </Button>
            </div>
            {locationData?.length > 0 ? (
              <div
                style={{
                  overflowY: "auto",
                  flex: 1,
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  marginTop: "2px",
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                      <TableCell align="right">Clicks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationData.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell align="right">{row.percentage}</TableCell>
                        <TableCell align="right">{row.clicks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Found
              </p>
            )}
          </Card>
        </Grid>


        {/* Top Cities Table */}
        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>
                <b className={styles.title}>Top Cities</b>
              </h4>
            </div>
            {cityData?.length > 0 ? (
              <div style={{
                overflowY: "auto",
                flex: 1,
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>City</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                      <TableCell align="right">Clicks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cityData.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell align="right">{row.percentage}</TableCell>
                        <TableCell align="right">{row.clicks}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Found
              </p>
            )}
          </Card>
        </Grid>

        {/* Os */}
        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
            }}
          >
            <h4>
              <b className={styles.title}>Operating Systems</b>
            </h4>
            {osData?.length > 0 ? (
              <ReactEcharts
                option={DoughnutChart(
                  "Operating Systems",
                  ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
                  "vertical",
                  10,
                  50,
                  osData,
                  ["40%", "70%"],
                  false
                )}
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Found
              </p>
            )}
          </Card>
        </Grid>

        {/* Top Browsers */}
        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>
                <b className={styles.title}>Top Browsers</b>
              </h4>
            </div>
            {browserData?.length > 0 ? (
              <div style={{
                overflowY: "auto",
                flex: 1,
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                marginTop: '2px'
              }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Browser</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                      <TableCell align="right">Clicks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {browserData.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.value}%</TableCell>
                        <TableCell align="right">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Found
              </p>
            )}
          </Card>
        </Grid>

        {/* Devices Chart */}
        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
            }}
          >
            <h4>
              <b className={styles.title}>Devices</b>
            </h4>
            {devicesData?.length > 0 ? (
              <ReactEcharts
                option={DoughnutChart(
                  "Devices",
                  ["#5368F0", "#9D57D5", "#FEAB00"],
                  "vertical",
                  10,
                  50,
                  devicesData,
                  ["40%", "70%"],
                  false
                )}
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Card>
        </Grid>

        {/* Referrers Chart */}
        <Grid item xs={6}>
          <Card
            style={{
              padding: 20,
              height: "400px",
            }}
          >
            <h4>
              <b className={styles.title}>Referrers</b>
            </h4>
            {referresData?.length > 0 ? (
              <AudienceProgressChart
                chartTitle=""
                parentStyle={styles}
                data={referresData}
                country={false}
                tooltip={true}
                tooltipText={
                  "Referrals show you sites or apps that referred visitors to your destination site by clicking a link."
                }
              />
            ) : (
              <p
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Graph found
              </p>
            )}
          </Card>
        </Grid>


      </Grid>

      {/* Modal for Details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        disableBackdropClick
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalTitle} Details
          </Typography>
          {modalContent === "countries" && locationData && (
            <div style={{ marginTop: "20px" }}>
              <CountryMapWithTable
                data={locationData}
                mapData={worldData}
                backgroundColor="white"
              />
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

CampaignLinkGraphs.propTypes = {
  linkClicksY: PropTypes.array.isRequired,
  linkClicksX: PropTypes.array.isRequired,
  devicesData: PropTypes.array.isRequired,
  referresData: PropTypes.array.isRequired,
  locationData: PropTypes.array.isRequired,
  worldData: PropTypes.array.isRequired,
  cityData: PropTypes.array.isRequired,
};

export default CampaignLinkGraphs;
