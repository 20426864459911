import { FormControl, Grid, InputLabel, MenuItem, Select, styled } from "@mui/material";
import React, { useEffect } from "react";
import { Clear } from "@mui/icons-material";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";
import {
  StyledStaticButton,
  StyledStaticButtonGroup,
} from "../../../common/StyledComponents";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import { getFormattedDate } from "../../../../utility/momentManipulations";

const StyledSelect = styled(Select)(({ theme }) => ({
  lineHeight: 1.6,
  minWidth: "7vw",
  marginRight: "5px",
  borderRadius: "10px",
  background: "#F7F7F7",
  "& .MuiSelect-endAdornment": {
    position: "absolute",
    right: 0,
  },
}));

const CampaignContractFilters = ({
  allContractFilters,
  applyFilters,
  selectedStartDate,
  selectedEndDate,
  defaultStartDate,
  defaultEndDate,
  updateDateRange,
  selectedTab,
}) => {
  const [contractTemplates, setContractTemplates] = React.useState([]);
  const [contractSentOn, setContractSentOn] = React.useState([]);
  const [contractStatus, setContractStatus] = React.useState([]);
  const [state, setState] = React.useState({
    template: "",
    status: "",
    startDate: selectedStartDate,
    endDate: selectedEndDate
  });
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      template: "",
      status: "",
    }));
  }, [selectedTab]);
  useEffect(() => {
    setContractTemplates(allContractFilters.contractName || []);
    setContractStatus(allContractFilters.contractStatus || []);
  }, [allContractFilters]);
  useEffect(() => {
    applyFilters(state);
  }, [state]);

  const reset = () => {
    setState({
      template: "",
      status: "",
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
    updateDateRange(defaultStartDate, defaultEndDate);
    applyFilters({
      template: "",
      status: "",
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
  };
  React.useEffect(() => {
    setContractTemplates(allContractFilters.contractName || []);
    setContractSentOn(allContractFilters.contractDate);
    setContractStatus(allContractFilters.contractStatus || []);
  }, [allContractFilters]);


  const sendSelectedDates = (event) => {
    const newStartDate = getFormattedDate(event.startDate, "YYYY-MM-DD");
    const newEndDate = getFormattedDate(event.endDate, "YYYY-MM-DD");
    setState((prevState) => ({
      ...prevState,
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    updateDateRange(newStartDate, newEndDate);
  };
  React.useEffect(() => {
    applyFilters(state);
  }, [state]);
  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Grid item md={2} xs={12}>
          <DateRangeCustomPicker
            key={`${state.startDate}-${state.endDate}`}
            sendSelectedDates={sendSelectedDates}
            parentStartDate={state.startDate}
            parentEndDate={state.endDate}
            needRange={true}
            sx={{ height: "100%", flexGrow: 1, display: "flex" }}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="template">Template Name</InputLabel>
            <StyledSelect
              id="demo-simple-select"
              required={true}
              variant="outlined"
              label="Template Name"
              value={state.template}
              onChange={(e) => setState({ ...state, template: e.target.value })}
              endAdornment={
                // Add the clear icon as the endAdornment
                state.template && (
                  <Clear
                    onClick={(e) => setState({ ...state, template: "" })}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "50%",
                      right: 25,
                      transform: "translateY(-50%)",
                    }}
                  />
                )
              }
            >
              {contractTemplates?.map((template) => (
                <MenuItem key={template} value={template}>
                  {template}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        
        <Grid item md={2} xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="template">Status</InputLabel>
            <StyledSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              required={true}
              variant="outlined"
              label="Status"
              value={state.status}
              onChange={(e) => setState({ ...state, status: e.target.value })}
              endAdornment={
                // Add the clear icon as the endAdornment
                state.status && (
                  <Clear
                    onClick={(e) => setState({ ...state, status: "" })}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "50%",
                      right: 25,
                      transform: "translateY(-50%)",
                    }}
                  />
                )
              }
            >
              {contractStatus?.map((template) => (
                <MenuItem key={template} value={template}>
                  {template}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item md={1} xs={12}>
          <FormControl fullWidth>
            <StyledStaticButton
              value="active"
              style={{ height: "45px", borderRadius: "10px" }}
              onClick={reset}
              disabled={state.template == "" && state.status == ""}
            >
              <b>Reset</b>
            </StyledStaticButton>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignContractFilters;
