import { Alert, AlertTitle, Autocomplete, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { func } from "prop-types";
import { useEffect, useState } from "react";
import { textColor, snapshotHeaderIcons } from "../../../../../utility/plaformIcons";
import TransferList from "../../../../common/TransferList";
import { getAllInfluencerListForCampaign, getInfluencerIdByList, getInfluencerWithInvitationStatus, inviteInfluencerByList } from "../../../../../services/influencer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../../../../../redux/features/system/systemAlert";
import configs from "../../../../../config/main.json";
import MyPaper from "../../../../../utility/oryxUtils/MyPaper";

const svg_icon = `${configs.BASE_IMAGE_URL}Mask Group 1.svg`;
const svg_icon2 = `${configs.BASE_IMAGE_URL}Group 26779.svg`;

const InviteByList = ({ setNavigateBack, groupList, campName }) => {
    const [selectedList, setSelectedList] = useState({ name: "" });
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [transferList, setTransferList] = useState(false);
    const user = useSelector((state) => state.user); //get loggedIn user state
    const dispatch = useDispatch();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const campaignId = params.get("id");
    const { PLATFORM_NAME } = configs;
    const [campaignName, setCampaignName] = useState(campName)

    const goback = () => {
        setNavigateBack(true);
    };

    const chooseList = async (newValue) => {
        var iids = [];
        if (newValue && newValue.id && newValue.platform) {
            const data = await getInfluencerIdByList(newValue.id, user, dispatch);
            setTransferList(true);
            data?.map((value) => {
                iids.push(value.iid);
            });
            let influencers;
            if (iids.length > 0) {
                influencers = await getInfluencerWithInvitationStatus(iids, newValue.id.toString(), newValue.platform, user, dispatch);
            }
            const left = [];
            const right = [];
            if (influencers?.response && influencers?.response.length > 0) {
                influencers?.response.forEach((influencer) => {
                    const inf = {};
                    const details = getDetails(influencer);
                    inf["id"] = influencer.influencer_id;
                    inf["label"] = details.name;
                    inf["disabled"] = details.disabled;
                    left.push(inf);
                });
            }
            setLeft(left);
            setRight(right);
         }
    };

    const getDetails = (influencer) => {
        let name;
        let disabled;
        if (influencer.invitecode < 0) {
            name = `${influencer.name} (Not Invited)`;
            disabled = false;
        } else if (influencer.invitecode === 0) {
            name = `${influencer.name} (Invitation Accepted)`;
            disabled = true;
        } else {
            name = `${influencer.name} (Invited but not accepted)`;
            disabled = true;
        }
        return { name, disabled };
    };

    const rollOutInvitation = () => {
        if (!selectedList.id) {
            dispatch(
                notify({
                    message: "List is mandatory",
                    type: "error",
                })
            );
        } else {
            dispatch(
                notify({
                    message: "Please wait while we send out invitations to the influencers..",
                    type: "info",
                })
            );
            var influencer_ids = [];
            influencer_ids = right.map((influencer) => influencer.id);
            const notificationMetadata = {
                userId: JSON.parse(user.userId),
                sourcePlatform: PLATFORM_NAME,
                notificationTypeId: "4",
                notificationActionId: "2",
                notificationCategoryId: "5",
                generatedBy: user.name,
                priority: "medium",
                consumedBy: "gazelle",
            };

              inviteInfluencerByList(
                Number(campaignId),
                selectedList.id,
                influencer_ids,
                campaignName,
                selectedList.platform,
                "invite",
                notificationMetadata,
                user, 
                dispatch
               )
            .then((result) => {
                if (result) {
                  setSelectedList({ name: "" });
                  setTransferList(false);
                  setCampaignName();
                  setRight([]);
                  dispatch(
                    notify({
                      message: "Email Sent to the influencers",
                      type: "success",
                    })
                  );
                }
              });
        }
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) => option.name}
                        options={groupList ? groupList : []}
                        value={selectedList}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <Typography sx={textColor[option.platform]}>{snapshotHeaderIcons[option.platform]}</Typography>
                                &nbsp;
                                {option.name}
                            </Box>
                        )}
                        onChange={(event, newValue) => {
                            setSelectedList(newValue);
                            chooseList(newValue)
                            setTransferList(true);
                        }}
                        renderInput={(params) => <TextField {...params} label="Group which has all your influencers" />}
                    />
                </Grid>
            </Grid>
            <br />
            {transferList ? (
                <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} />
            ) : (
                <Box sx={{ height: '37.3vh', backgroundColor: "#ffffff", boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px" }}>
                    <div style={{ textAlign: "center", width: "50vw", margin: "0 auto", height: "auto", paddingTop: "50px" }}>
                        <div style={{ position: "relative", display: "inline-block", paddingBottom: "10px" }}>
                            <img
                                src={svg_icon2}
                                alt="Icon"
                                style={{
                                    position: "absolute",
                                    top: "-15px",
                                    left: "18px",
                                    zIndex: 2,
                                }}
                            />
                            <img
                                src={svg_icon}
                                alt="Icon"
                                style={{
                                    position: "relative",
                                    zIndex: 1,
                                    width: '120px',
                                    height: '120px'
                                }}
                            />
                        </div>
                        <p style={{ color: "#34343F", fontSize: "18px" }}><b>No group is selected yet</b></p>
                        <p style={{ width: "250px", textAlign: "center", margin: "0 auto", color: "#464665", fontSize: "14px" }}>
                            Start by selecting a group.
                        </p>
                    </div>
                </Box>
            )}
            <Grid item xs={12} spacing={2} sx={{ textAlign: "right" }}>
                <Box sx={{ mt: 4 }}>
                    <Button variant="outlined" size="large" type="submit" onClick={goback} sx={{width: "180px"}}>
                        Go back
                    </Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button variant="contained" size="large" type="submit" disabled={right.length === 0} onClick={rollOutInvitation} >
                        {right.length === 0
                            ? "Invite Influencers"
                            : `Invite ${right.length} Influencer${right.length > 1 ? "s" : ""}`}
                    </Button>
                </Box>
            </Grid>
        </>
    );
};

export default InviteByList;