import React from "react";

import { otherIcons, textColor } from "../../../../utility/plaformIcons";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CampaignContractList from "./CampaignContractList";
import { Box } from "@mui/material";
import CampaignContractTemplates from "./CampaignContractTemplate";
import CampaignContractFilters from "./CampaignContractFilters";
import { useLocation } from "react-router-dom";
import DummyCampaign from "../../../common/DummyCampaign";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";

const defaultStartDate = getFormattedDate(
  moment().subtract(6, "months").toDate(),
  "YYYY-MM-DD"
);
const defaultEndDate = getFormattedDate(moment().toDate(), "YYYY-MM-DD");

const CampaignContractsSnapshot = () => {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const tabId = +params.get("tabId");
  const [selectedTab, setSelectedTab] = React.useState(tabId ? tabId : 0);
  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  const updateDateRange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };
  const universal = params.get("universal");
  const [filters, setFilters] = React.useState([]);
  const [allContractFilters, setAllContractFilters] = React.useState({});
  const [contractFilters, setContractFilters] = React.useState({});
  const [contractTemplateFilters, setContractTemplateFilters] = React.useState({});
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const recieveSelectedValues = (event) => {
    setFilters(event);
  };

  const sendFilters = (event) => {
    setAllContractFilters(event);
  };

  const applyFilters = (event) => {
    setFilters(event);
  };

  return (
    <Box>
      <DummyCampaign universal={universal} />
      <CampaignContractFilters
         allContractFilters={
          selectedTab === 0 ? contractFilters : contractTemplateFilters
        }
        applyFilters={applyFilters}
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        updateDateRange={updateDateRange}
        selectedTab={selectedTab}
      />
      <br />
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={selectedTab}>
          <Box sx={{ bgcolor: "background.paper" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              return (
              <Tab
                icon={otherIcons["contracts"]}
                sx={{
                  ...textColor["twitter"],
                  "&.Mui-selected": {
                    color: textColor["twitter"], // Change to desired color for selected state
                  },
                }}
                label={"Contracts"}
                value={0}
              />
              <Tab
                icon={otherIcons["contractTemplate"]}
                sx={{
                  ...textColor["facebook"],
                  "&.Mui-selected": {
                    color: textColor["facebook"], // Change to desired color for selected state
                  },
                }}
                label={"Contract Templates"}
                value={1}
              />
              );
            </TabList>
          </Box>
        </TabContext>
      </Box>
      <br />

      {selectedTab === 0 && (
        <CampaignContractList
          sendFilters={setContractFilters}
          filters={filters}
          tabId={selectedTab}
        />
      )}
      {selectedTab === 1 && (
        <CampaignContractTemplates
          sendFilters={setContractTemplateFilters}
          filters={filters}
          tabId={selectedTab}
        />
      )}
    </Box>
  );
};

export default CampaignContractsSnapshot;
