import { Box, Button, Grid, TextField, Typography, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../../../../../redux/features/system/systemAlert";
import { checkForCredit } from "../../../../../services/credit";
import { getAllGroupsForCampaign } from "../../../../../services/group";
import { addPublications, getPublicationCount } from "../../../../../services/publication";
import styles from "../../../../../styles/internal/Campaign/CreateNewCampaign.css";
import { PlatformIdMapping, snapshotHeaderIcons, textColor } from "../../../../../utility/plaformIcons";
import { StyledStaticButton, StyledStaticButtonGroup } from "../../../../common/StyledComponents";
import configs from "../../../../../config/main.json";
const AddCampaignPublications = (props) => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const data = location.state || props?.data;
  const source = location.state?.source;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignId = params.get("id");
  const universal = params.get("universal");
  const [platforms, setPlatforms] = useState(Object.keys(PlatformIdMapping));
  const [allGroups, setAllGroups] = useState([]);
  const [state, setState] = useState({
    platform: "",
    publicationUrls: "",
    allowedPublicationCount: 0,
    alloweddInfluencerCount: 0,
    hint: "* Provide comma-separated Publication URLs belonging to the campaign.",
  });

  useEffect(() => {
    getGroupsForCampaign();
    getPublicationsCount();
    if (data?.data !== null) {
      setPlatforms(data?.data);
    }
  }, []);

  const getGroupsForCampaign = async () => {
    const response = await getAllGroupsForCampaign(campaignId, user, dispatch);
    setAllGroups(response?.response);
  };

  const getPublicationsCount = async () => {
    const data = {
      name: ["Campaigns.Fields.Publications", "Campaigns.Fields.Influencers"],
      accountId: user.agencyId,
      clientId: user.clientId,
    };
    const response = await checkForCredit(data, user, dispatch);
    setState({
      ...state,
      allowedPublicationCount: parseInt(response?.response.message[data.name[0]]),
      alloweddInfluencerCount: parseInt(response?.response.message[data.name[1]]),
    });
  };
  const addPosts = async () => {
    //cleaning the post id string value
    let publicationUrls = state.publicationUrls.replaceAll(",", "\n").split("\n");
    publicationUrls = publicationUrls
      ?.map((publicationUrl) => publicationUrl.trim())
      ?.filter((publicationUrl) => publicationUrl !== "");
    const selectedLid = allGroups?.filter((lid) => {
      return PlatformIdMapping[lid.platform.toLowerCase()] === PlatformIdMapping[state.platform];
    });
    if (state.platform !== "facebook" && state.platform !== "twitter") {
      const platformCount = publicationUrls?.filter((data) => data.includes(state.platform));
      if (platformCount?.length !== publicationUrls?.length) {
        dispatch(
          notify({
            message: `Please add only ${state.platform} posts`,
            type: "error",
          })
        );
        return;
      }
    }
    if (user.agencyId > 0) {
      if (publicationUrls?.length > state.allowedPublicationCount) {
        dispatch(
          notify({
            message: `You can only add ${state.allowedPublicationCount} publication and ${state.alloweddInfluencerCount
              } influencers in your current subscrption. Please delete ${publicationUrls?.length - state.allowedPublicationCount
              } publications to continue or upgrade your plan`,
            type: "error",
          })
        );
        return;
      }
    }
    if (publicationUrls.includes("stories")) {
      dispatch(
        notify({
          message: "Sorry, we don't support stories and reels at the moment. Please exclude reels and story urls.",
          type: "error",
        })
      );
      return;
    }
    const postData = {
      cid: parseInt(campaignId),
      lid: selectedLid[0].id,
      iid: 0,
      platform: PlatformIdMapping[state.platform],
      postType: 1,
      postIds: publicationUrls,
      storyData: [],
      isSaas: true,
      agencyId: user.agencyId,
      notificationMetadata: {
        userId: `agency/${user.agencyId}`,
        sourcePlatform: configs.PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "2",
        notificationCategoryId: "1",
        generatedBy: user.email,
        priority: "high",
        consumedBy: "empowr",
        navigateTo: "/campaigns/publications/published?id=" + campaignId,
      },
    };
    dispatch(
      notify({
        message: "Please wait... We are processing your publications",
        type: "info",
      })
    );
    const response = await addPublications(postData, user, dispatch);
    if (response?.response?.status === "success") {
      dispatch(
        notify({
          message: "Publications added successfully",
          type: "success",
        })
      );
      if (props?.data?.handleCloseModal) {
        props.data.handleCloseModal();
      } else {
        navigate(`/app/campaigns/publications/published?id=${campaignId}&universal=${universal}`, { replace: true });
      }
      return;
    } else {
      dispatch(
        notify({
          message: response?.response.status,
          type: "error",
        })
      );
    }
  };

  const handleBack = () => {
    if (source === "overview") {
      navigate(`/app/campaigns/overview?id=${campaignId}&universal=${universal}`)
    }
    if (source === "snapshot") {
      navigate(`/app/campaigns/analytics/snapshot?id=${campaignId}&universal=${universal}`)
    }
  };


  const addPublicationsValues = (e) => {
    let publicationUrls = state.publicationUrls.replaceAll(",", "\n").split("\n");
    publicationUrls = publicationUrls
      ?.map((publicationUrl) => publicationUrl.trim())
      ?.filter((publicationUrl) => publicationUrl !== "");

    setState({ ...state, publicationUrls: e.target.value });
  };

  const addPlatform = (platform) => {
    if (platform === "facebook" || platform === "linkedin") {
      setState({ ...state, platform, hint: `Please add public posts only for ${platform}` });
    } else {
      setState({ ...state, platform , hint: `* Provide comma-separated Publication URLs belonging to the campaign.` });
    }
  };
  return (
    <>
      {/* {user.agencyId > 0 && (
        <Box style={{marginLeft: "28px", marginRight: "28px", fontSize: "15px" , paddingTop: "20px", color:"gray"}}>
          You can add {state.allowedPublicationCount} {state.allowedPublicationCount < 50 && "more"} posts and{" "}
          {state.alloweddInfluencerCount} {state.alloweddInfluencerCount < 5 && "more"} influencers as per your subscription.
          Please upgrade your plan to add more posts
        </Box>
      )} */}
      <Box m={2} style={{ backgroundColor: "#FAFAFB", padding: "10px" }}>
        <Box>
          <h4 style={{ color: "gray", fontWeight: "600", textAlign: "start", fontSize: "18px" }}>Choose Social Network</h4>
          <Grid container item spacing={1} xs={12} style={{ paddingTop: 20 }}>
            {platforms?.map((platform) => (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: state.platform === platform ? textColor[platform].color : "gray",
                    padding: "10px",
                  }}
                  onClick={() => addPlatform(platform)}
                >
                  {snapshotHeaderIcons[platform] &&
                    React.cloneElement(snapshotHeaderIcons[platform], {
                      style: {
                        ...(
                          platform === "tiktok"
                            ? {
                              filter: state.platform?.includes(platform)
                                ? "grayscale(10%)"
                                : "invert(20%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(10%)",
                              height: "20px",
                            }
                            : {
                              fill: state.platform?.includes(platform)
                                ? textColor[platform].color
                                : "gray",
                              height: "20px",
                            }
                        )
                      },
                    })}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Grid container item spacing={3} xs={12} style={{ paddingTop: 20 }}>
            <Grid item md={12} xs={12}>
              <TextField
                style={{ width: "100%" }}
                variant="filled"
                label="Publication URLs"
                placeholder="Enter the list of Post Urls separated by commas or in new lines."
                multiline
                rows={8}
                value={state.publicationUrls}
                onChange={(e) => addPublicationsValues(e)}
                helperText={state.hint}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" alignItems="center">
            {!props.data.handleCloseModal && (
              <Grid item>
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="outlined"
                    style={{
                      width: "180px",
                      height: "38px",
                      color: "#474747",
                      borderColor: "#474747",
                      borderRadius: "11px",
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item>
              <Box sx={{ mt: 4 }}>
                <StyledStaticButtonGroup
                  color="primary"
                  size="small"
                  exclusive
                  aria-label="Campaign"
                  disabled={!state.platform || !state.publicationUrls}
                >
                  <StyledStaticButton value="active" className={styles.staticButton} onClick={addPosts}>
                    <b>Add</b>
                  </StyledStaticButton>
                </StyledStaticButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AddCampaignPublications;
