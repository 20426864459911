import { Box, Button, Card, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { tableHeaders } from "./CommonAssets";
import { getFormattedDate } from "../../../../utility/momentManipulations";
import moment from "moment";
import DateRangeCustomPicker from "../../../common/daterangepicker";
import Loader from "../../../common/loader";
import { exportSentimentStats, getSentimentStats } from "../../../../services/sentimentAnalytics";
import ReactEcharts from "echarts-for-react";
import { DoughnutChart, StackedLineChart } from "../../../common/chart";
import { logout } from "../../../../redux/features/user/userSlice";
import { notify } from "../../../../redux/features/system/systemAlert";
import DummyCampaign from "../../../common/DummyCampaign";
import { Close, ImportExport, RemoveRedEyeOutlined } from "@mui/icons-material";
import { onDownloadHandle } from "../../../../utility/exportData";
import { getCampaignName } from "../../../../utility/sessionData";
import CustomizedTable from "../../../common/customizedTable";
import styles from "../../../../styles/internal/Campaign/CampaignList.module.css";

const SentimentAnalyticsSnapshot = () => {
  const user = useSelector((state) => state.user); //get loggedIn user state
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [campaignName] = React.useState(getCampaignName());
  const [startDate, setStartDate] = useState(getFormattedDate(moment().subtract(6, "months").toDate(), "YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(getFormattedDate(moment().toDate(), "YYYY-MM-DD"));
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);
  const [donutData, setDonutData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [headers, setHeaders] = React.useState(tableHeaders);
  const [bodyColumns, setBodyColumns] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(10);

  const universal = params.get("universal");
  useEffect(() => {
    getSentimentAnalytics(params.get("id"), startDate, endDate);
  }, [+params.get("id"), startDate, endDate]);

  const getSentimentAnalytics = async (cid, from, to) => {
    setLoading(true);
    setDataFound(false);

    const sentiments = await getSentimentStats(cid, from, to, user, dispatch);

    setLoading(false);

    if (sentiments.status === true && sentiments.response !== null) {
      const data = sentiments.response;
      let seriesData = { Positive: [], Negative: [], Neutral: [] };
      let xAxisData = [];
      let positive = 0,
        negative = 0,
        neutral = 0;
      const keys = Object.keys(data);
      if (keys?.length > 0) {
        setDataFound(true);
      } else {
        return;
      }

      keys.forEach((val) => {
        xAxisData.push(val);
        seriesData.Positive.push(data[val].positive);
        seriesData.Negative.push(data[val].negative);
        seriesData.Neutral.push(data[val].neutral);
        positive += data[val].positive;
        negative += data[val].negative;
        neutral += data[val].neutral;
      });
      let total = positive + negative + neutral;
      let positiveData = (positive / total) * 100;
      let negativeData = (negative / total) * 100;
      let neutralData = (neutral / total) * 100;

      const allData = [
        { value: positiveData.toFixed(2), name: "Positive(%)" },
        { value: negativeData.toFixed(2), name: "Negative(%)" },
        { value: neutralData.toFixed(2), name: "Neutral(%)" },
      ];

      const donutData = allData?.filter(d => parseFloat(d.value) !== 0);

      setXAxisData(xAxisData);
      setSeriesData(seriesData);
      setDonutData(donutData);
    } else {
      setDataFound(false);
      if (sentiments.response?.message?.includes("expired")) {
        dispatch(logout());
      }
      dispatch(
        notify({
          message: sentiments.response?.message,
          type: "error",
        })
      );
    }
  };

  const setSelectedDates = (event) => {
    setStartDate(getFormattedDate(event.startDate, "YYYY-MM-DD"));
    setEndDate(getFormattedDate(event.endDate, "YYYY-MM-DD"));
  };

  const prepareRawData = async (page, rows) => {
    const exportedData = await exportSentimentStats(params.get("id"), startDate, endDate, false, page+1, rows, user, dispatch);
    if(exportedData.status){
      setTotalRows(exportedData.response.total_records);
      createData(exportedData.response.sentimentRawData)
      setModalOpen(true)
    }
  }

  const sendInformation = (event) => {
    setPage(event.page);
    setRows(event.rowsPerPage);
    prepareRawData(event.page, event.rowsPerPage);
  };


  const createData = (records) => {
    const columns = [];
    records.forEach((data) => {
      let object = {
        comment: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {data.comment}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        sentiment: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {data.sentiment}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        ),
        score: (
          <Grid container spacing={1}>
            <Grid item xs={10} className={styles.bodycell}>
              {data.score}
            </Grid>
            <Grid item xs={2} className={styles.bodycell}></Grid>
          </Grid>
        )
      };
      columns.push(object);
    });

    setBodyColumns(columns);
  };

  const handleClose = () => {
    setModalOpen(false);
  }

  const downloadData = async () => {
    dispatch(notify({
      message: "Please wait... We are preparing your file for download",
      type: "info",
    }))
    const exportedData = await exportSentimentStats(params.get("id"), startDate, endDate, true, page+1, rows, user, dispatch);
    const fileName = `Sentiment_Analysis_Raw_Exports_${campaignName}.csv`;
    if (exportedData.response !== undefined) {
      handleClose();
      onDownloadHandle(new Blob([exportedData.response]), fileName);
      dispatch(notify({
        message: `File ${fileName} downloaded succesfully`,
        type: "success",
      }))
    }
  }
 
  return (
    <Box m={3}>
      <DummyCampaign universal={universal} />
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <DateRangeCustomPicker sendSelectedDates={setSelectedDates} parentStartDate={startDate} parentEndDate={endDate} needRange={true} />
        </Grid>
      </Grid>
      <Modal open={modalOpen} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box style={{ backgroundColor: "#FAFAFB", padding: "10px", maxWidth: "800px", width: '100%', maxHeight: '95%', overflowY: 'auto' }}>
        <Box m={3} position="relative">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={handleClose} style={{ position: 'absolute', right: 0 }}>
              <Close/>
            </IconButton>
          </Box>
          <CustomizedTable
              tableHeaders={headers}
              bodyColumns={bodyColumns}
              totalRows={totalRows}
              pageNo={page}
              rowsPerPageNo={rows}
              sendInformation={sendInformation}
            />
            <br/>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Grid container>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <Button variant="contained" className={`${styles.button}`} onClick={() => downloadData()}>
                  Download
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
      {loading ? (
        <Loader />
      ) : dataFound ? (
        <>
          <Card sx={{ p: "20px", borderRadius: "10px", mt: "20px" }}>
            <Grid container>
              <Grid item xs={11.8}><ReactEcharts
              option={{
                ...StackedLineChart("Sentiment Analytics", xAxisData, ["Positive", "Negative", "Neutral"], seriesData),
                series: Object.keys(seriesData)?.map((key) => ({
                  name: key,
                  type: "line",
                  data: seriesData[key],
                  markPoint: {
                    data: [
                      { type: 'max', name: 'Peak' },
                    ],
                    label: {
                      show: true,
                      formatter: '{c}',  
                      fontSize: 8, 
                    },
                    itemStyle: {
                      color: 'white',
                      borderColor: key === 'Positive' ? '#91cc75' : key === 'Negative' ? '#ee6666' : '#fac858',
                      borderWidth: 2,
                    },
                    symbolSize: 35,
                  },
                })),
                color: ["#91cc75", "#ee6666", "#fac858"],
              }}
              style={{ height: "400px", width: "100%" }}
            /></Grid>
            <Grid item xs={0.2} style={{ cursor: "pointer"}} onClick={() => prepareRawData(page, rows)}>
              <Tooltip title="View raw data"><RemoveRedEyeOutlined fontSize="small" sx={{ color: "grey"}} /></Tooltip>
            </Grid>
            </Grid>
          </Card>
          <Card sx={{ p: "20px", borderRadius: "10px", mt: "20px" }}>
            <ReactEcharts
              option={{
                ...DoughnutChart(
                  "",
                  [],
                  "vertical",
                  "90",
                  "180",
                  donutData,
                  ["40%", "70%"],
                  true,
                  false,
                  true,
                  {},
                  {},
                  "Overall Sentiments"
                ),
                color: ["#91cc75", "#ee6666", "#fac858"],
              }}
              style={{ height: "400px", width: "100%" }}
            />
          </Card>
        </>
      ) : (
        <>
          <Card
            sx={{
              p: "20px",
              borderRadius: "10px",
              mt: "20px",
              height: "440px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              Sentiment Analytics
            </Typography>
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Graph found
            </p>
          </Card>
          <Card
            sx={{
              p: "20px",
              borderRadius: "10px",
              mt: "20px",
              height: "440px",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>Overall Sentiments</Typography>
            <p
              style={{
                height: "400px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Graph found
            </p>
          </Card>
        </>
      )}
      <br />
    </Box>
  );
};

export default SentimentAnalyticsSnapshot;
